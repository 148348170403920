import { createCustomTheme, theme } from '../../theme';
import { VenueAttributesDTO } from '../../store/storeModels';

export const getAlteredTheme = (accountAttributes: VenueAttributesDTO) => {
  if (!accountAttributes) {
    return theme;
  }

  const primaryColor = accountAttributes.properties?.['webapp.config']?.['primary-color'];
  const secondaryColor = accountAttributes.properties?.['webapp.config']?.['secondary-color'];
  const textColor = accountAttributes.properties?.['webapp.config']?.['text-color'];

  return createCustomTheme({
    primaryColor,
    secondaryColor,
    textColor,
  });
};
