import React, { FC, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './Toast.helper';
import { CloseIcon } from '../../assets/CloseIcon';
import { SubmitIcon } from '../../assets/SubmitIcon';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { closeToast } from '../../../store/slices/toast';

export enum ToastTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export const ToastComponent: FC = () => {
  const dispatch = useAppDispatch();
  const toast = useTypedSelector((state) => state.toast);
  let backgroundColor: string = '#5DD6AA';
  const classes = useStyles({ backgroundColor });
  let icon;

  switch (toast.type) {
    case ToastTypes.SUCCESS:
      icon = <SubmitIcon classes={classes.svgIcon} />;
      backgroundColor = '#5DD6AA';
      break;
    case ToastTypes.WARNING:
      icon = (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.16114 0.322144C1.8663 -0.134557 2.6607 0.0542388 3.22992 0.275453C3.98284 0.564215 5.05606 1.18281 6.02921 1.82669C7.00346 2.4713 8.01053 3.22922 8.59378 3.84168L8.59648 3.84452C8.87963 4.14401 9.30282 4.63447 9.33174 5.30561C9.36207 6.00946 8.95305 6.53969 8.60859 6.90111C8.0448 7.49914 7.07621 8.23358 6.13111 8.86495C5.17836 9.50144 4.13 10.1124 3.37 10.4275C2.77808 10.6722 1.93972 10.9065 1.19339 10.4423C0.490536 10.0051 0.293779 9.19412 0.201821 8.65051L0.201135 8.64641C0.0629046 7.80962 -0.00126758 6.57548 1.89561e-05 5.38066C-0.00125999 4.13368 0.0673426 2.83336 0.224132 1.98989C0.314478 1.49482 0.507281 0.745621 1.16114 0.322144ZM2.35887 2.37824C2.36624 2.33781 2.37365 2.30098 2.38104 2.2675C2.40056 2.27452 2.42123 2.28225 2.44311 2.29077L2.44886 2.29299C2.97226 2.49336 3.88598 3.00435 4.8289 3.62823C5.77095 4.25153 6.60926 4.89972 7.01843 5.32888C7.03542 5.34687 7.05104 5.36375 7.06541 5.37961L7.0336 5.41353L7.02849 5.41892C6.64044 5.83115 5.84004 6.45597 4.92277 7.06875C4.01215 7.67709 3.10264 8.19607 2.53782 8.43032C2.47905 8.45461 2.42704 8.47425 2.38127 8.49C2.36804 8.43407 2.35484 8.36884 2.34199 8.29306C2.23236 7.62794 2.16876 6.53102 2.17003 5.38187L2.17003 5.37953C2.16875 4.1666 2.23839 3.02527 2.35811 2.3824L2.35887 2.37824Z"
              fill="white"
            />
          </svg>
        </>
      );
      backgroundColor = 'orange';
      break;
    case ToastTypes.ERROR:
      backgroundColor = 'red';
      break;
    default:
      icon = <SubmitIcon classes={classes.svgIcon} />;
  }

  const handleSnackClose = useCallback(() => {
    dispatch(
      closeToast({
        message: '',
        type: toast.type,
        isDismissed: !toast.isDismissed,
        toastInvoked: !toast.toastInvoked,
      }),
    );
  }, [toast, dispatch]);

  useEffect(() => {
    if (!toast.isDismissed) {
      setTimeout(() => {
        handleSnackClose();
      }, 6000);
    }
  }, [toast, handleSnackClose]);
  if (toast.isDismissed) {
    return <div></div>;
  }
  return (
    <Box
      className={[
        classes.snackBlock,
        toast.toastInvoked ? classes.open : '',
        toast.isDismissed ? classes.close : '',
      ].join(' ')}
    >
      <div className={classes.snackBlockIcon}>{icon}</div>
      <div className={classes.snackBlockContent}>{toast.message}</div>
      <button onClick={handleSnackClose} className={classes.snackBlockClose}>
        <CloseIcon />
      </button>
    </Box>
  );
};
