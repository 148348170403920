export interface AuthRequestBody {
  accountId?: string;
  name?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  password?: string;
  phoneNumber?: string | null;
  isEnterpriseCaptcha?: boolean;
  captcha?: string;
  instagramHandle?: string;
  returnUrl?: string;
  refId?: string;
  photoUrl?: string;
  campaignId?: string;
  source?: UserSource;
  defaultVideoDetails?: {
    metaTitle: string;
    videoText: string;
    ctaBtnText: string;
    ctaBtnLinkUrl: string;
    smsShareList?: Array<{ name: string }>;
  };
}

export enum UserSource {
  QrCode = 'QR_CODE',
  Url = 'URL',
  Sms = 'SMS',
}

export enum UserStatuses {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  archived = 'ARCHIVED',
}

export interface StoryDetails {
  title?: string;
  smsShareList?: Array<{ name: string }>;
  ctaBtnLinkUrl: string;
  ctaBtnText: string;
  editable?: string;
  metaDescription: string;
  metaTitle: string;
  videoText: string;
  videoMessage: string;
  webAppSettings?: {
    metaTitle: boolean;
    videoText: boolean;
    ctaBtnText: boolean;
    ctaBtnLinkUrl: boolean;
    videoMessage: boolean;
  };
}

export interface UserModel {
  accountId: string;
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  photoUrl: string;
  phoneNumber: string;
  instagramHandle: string;
  points: number;
  campaignPoints: number;
  returnUrl: string;
  scaleoAffiliateId: number | null;
  displayName: string | null;
  status: UserStatuses | '';
  defaultVideoDetails: StoryDetails | null;
  phoneNumberVerified: boolean;
}

export interface MeApiModel {
  user: UserModel;
  accessToken: string;
  refreshToken: string;
}

export interface ExistUserRequestBody {
  accountId: string;
  email: string;
}
export interface ExistUserApiModel {
  exists: boolean;
}

export interface ForgotPasswordRequest {
  email: string;
  accountId: string;
}

export interface ResetPasswordRequest {
  newPassword: string;
  token: string;
}
