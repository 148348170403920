import { createSlice } from '@reduxjs/toolkit';

export interface ToastState {
  toastInvoked: boolean;
  message: string;
  type: string;
  isDismissed?: boolean;
}

export const toastInitialState: ToastState = {
  toastInvoked: false,
  message: '',
  type: '',
  isDismissed: false,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState: toastInitialState,
  reducers: {
    invokeToast: (_, action) => {
      return {
        ...toastInitialState,
        ...action.payload,
      };
    },
    closeToast(_, action) {
      return {
        ...toastInitialState,
        ...action.payload,
      };
    },
  },
});

export const { invokeToast, closeToast } = toastSlice.actions;
export default toastSlice.reducer;
