import { FC, ReactNode, useEffect } from 'react';
import { ThemeProvider, Theme } from '@mui/material';
import { ThemeProvider as StylesThemeProvider } from '@mui/styles';

import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { getVenue, getProperties } from '../../../store/slices/venue';
import { getUserIncentiveCampaigns } from '../../../store/slices/rewards';
import { getUserRequest } from '../../../store/slices/me';
import { httpClient } from '../../../services/httpClient/httpClient';
import { getAlteredTheme } from '../../../services/hooks/themeHook';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface InitialContainerProps {
  children: ReactNode;
}

export const InitialContainer: FC<InitialContainerProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { account, accountAttributes } = useTypedSelector((state) => state.account);
  const alteredTheme = getAlteredTheme(accountAttributes);

  useEffect(() => {
    dispatch(getVenue());
  }, []);

  useEffect(() => {
    const userId = httpClient.getUserId();
    if (account.id) {
      dispatch(getProperties(account.id));
      if (userId) {
        dispatch(getUserRequest(userId));
        dispatch(getUserIncentiveCampaigns({ userId }));
      }
    }
  }, [dispatch, account.id]);

  return (
    <ThemeProvider theme={alteredTheme}>
      <StylesThemeProvider theme={alteredTheme}>{children}</StylesThemeProvider>
    </ThemeProvider>
  );
};
