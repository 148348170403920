import { ReactElement, useEffect, useState } from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Provider } from 'react-redux';
import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material';
import '../src/index.css';
import '../src/reset.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useStore } from '../src/store/store';
import { InitialContainer } from '../src/common/components/InitialContainer/InitialContainer';
import { theme } from '../src/theme';
import { init } from '../utils/sentry';
import { ToastComponent } from '../src/common/components/Toast/ToastComponent';

import { isMobile } from 'react-device-detect';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

if (process.env.APP_ENV !== 'development') {
  init();
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactElement;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const [, setLoading] = useState(true);

  useEffect(() => {
    const handleStart = (url: string) => setLoading(url !== router.pathname);
    const handleComplete = () => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);

  const isWindow = typeof window !== 'undefined';

  useEffect(() => {
    let vh = 768;
    if (isWindow) {
      vh = window.innerHeight * 0.01;
    }
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (!isMobile) {
      document.body.style.setProperty('overflow', 'hidden');
    }

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }

    const resize = () => {
      const doc = document.documentElement;

      const prevAppHeight = doc.style.getPropertyValue('--app-height').replace('px', '');

      if (!prevAppHeight || !isMobile || Math.abs(Number(prevAppHeight) - window.innerHeight) < 100) {
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
      }
    };
    window.addEventListener('resize', resize);
    resize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const store = useStore(pageProps.initialReduxState);
  const persistor = persistStore(store);
  const getLayout = Component.getLayout ?? ((page) => <>{page}</>);

  if (process.browser && isWindow) {
    return (
      <div suppressHydrationWarning>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StylesProvider injectFirst={false}>
              <ThemeProvider theme={theme}>
                <MaterialThemeProvider theme={theme}>
                  <StyledEngineProvider injectFirst>
                    <CssBaseline />
                    {/* <CrispWithNoSSR /> */}
                    <InitialContainer>{getLayout(<Component {...pageProps} />)}</InitialContainer>
                  </StyledEngineProvider>
                </MaterialThemeProvider>
              </ThemeProvider>
            </StylesProvider>
          </PersistGate>
          <ToastComponent />
        </Provider>
      </div>
    );
  }

  return (
    <div suppressHydrationWarning>
      <Provider store={store}>
        <StylesProvider injectFirst={false}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {/* <CrispWithNoSSR /> */}
              <InitialContainer>{<Component {...pageProps} />}</InitialContainer>
            </ThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
      </Provider>
    </div>
  );
}

export default appWithTranslation(MyApp);
