import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../services/httpClient/httpClient';
import { CampaignsEndpoints, getApiUrlForId, RewardEndpoints } from '../../api/endpoints';
import {
  Activity,
  ActivityApiModel,
  CampaignApiModel,
  UserIncentiveCampaignApiModel,
  RewardApiModel,
  UserActivityApiModel,
} from '../../api/models/rewards';

import axios from 'axios';

interface RewardsSlice {
  activeReward: RewardApiModel;
  activeCampaign: CampaignApiModel | null;
  currentCampaign: CampaignApiModel | null;
  activities: ActivityApiModel[];
  userActivities: UserActivityApiModel[];
  isActiveCampaign: boolean;
  isLoading: boolean;
  userIncentiveCampaigns: UserIncentiveCampaignApiModel[];
  primaryCampaign: CampaignApiModel | null;
}

export const initialRewardsState: RewardsSlice = {
  activeReward: {
    id: '',
    accountId: '',
    name: '',
    points: 1000,
    isActive: true,
    imageUrl: '',
    value: '',
  },
  activities: [],
  userActivities: [],
  isActiveCampaign: true,
  activeCampaign: null,
  currentCampaign: null,
  isLoading: false,
  userIncentiveCampaigns: [],
  primaryCampaign: null,
};

export const getActiveReward = createAsyncThunk(
  'rewards/getActiveReward',
  async (_options: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ accountId: string }, RewardApiModel>({
        url: getApiUrlForId(RewardEndpoints.GetActiveReward, _options),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getActiveCampaign = createAsyncThunk(
  'rewards/getActiveCampaign',
  async (_options: string, { rejectWithValue }) => {
    try {
      const campaign = await httpClient.get<{ accountId: string }, CampaignApiModel>({
        url: getApiUrlForId(RewardEndpoints.GetActiveCampaign, _options),
        requiresToken: true,
      });
      // campaign.activities = await httpClient.get<undefined, Activity[]>({
      //   url: getApiUrlForId(CampaignsEndpoints.GetActiveIncentiveCampaignActivities, campaign.id),
      //   requiresToken: true,
      // });
      return campaign;
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getPrimaryCampaign = createAsyncThunk(
  'rewards/getPrimaryCampaign',
  async (_options: { accountId: string }, { rejectWithValue }) => {
    try {
      return httpClient.get<{ accountId: string }, CampaignApiModel>({
        url: getApiUrlForId(RewardEndpoints.GetPrimaryCampaign, _options.accountId),
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getActiveIncentiveCampaignActivities = createAsyncThunk(
  'campaigns/getActiveIncentiveCampaignActivities',
  async (options: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, Activity[]>({
        url: getApiUrlForId(CampaignsEndpoints.GetActiveIncentiveCampaignActivities, options),
        requiresToken: true,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data.message);
      }
    }
  },
);

export const getActivities = createAsyncThunk(
  'rewards/getActivities',

  async (_options: {}, { rejectWithValue }) => {
    try {
      return await httpClient.get<{}, ActivityApiModel[]>({
        url: RewardEndpoints.GetActivities,
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUserActivities = createAsyncThunk(
  'rewards/getUserActivities',
  async (_options: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, UserActivityApiModel[]>({
        url: getApiUrlForId(RewardEndpoints.GetUserActivities, _options),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getCampaignById = createAsyncThunk(
  'rewards/getCampaignById',
  async (_options: { id: string }, { rejectWithValue }) => {
    try {
      return httpClient.get<undefined, CampaignApiModel>({
        url: getApiUrlForId(RewardEndpoints.GetCampaignById, _options.id),
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUserIncentiveCampaigns = createAsyncThunk(
  'rewards/getUserIncentiveCampaigns',
  async (_options: { userId: string; ongoing?: boolean }, { rejectWithValue }) => {
    try {
      const incentiveCampaigns = await httpClient.get<{ ongoing?: boolean }, UserIncentiveCampaignApiModel[]>({
        url: getApiUrlForId(RewardEndpoints.GetUserIncentiveCampaigns, _options.userId),
        requiresToken: true,
        params: {
          ongoing: _options.ongoing,
        },
      });

      const campaignDetailsPromises = [];

      for (const { campaignId } of incentiveCampaigns) {
        campaignDetailsPromises.push(
          httpClient.get<undefined, CampaignApiModel>({
            url: getApiUrlForId(RewardEndpoints.GetCampaignByIdWithStats, campaignId),
            requiresToken: true,
          }),
        );
      }

      const campaignDetails = await Promise.all(campaignDetailsPromises);
      incentiveCampaigns.forEach((campaign, index) => {
        campaign.campaign = campaignDetails[index];
      });

      return { incentiveCampaigns };
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

const RewardsSlice = createSlice({
  name: 'rewards',
  initialState: initialRewardsState,
  reducers: {
    setCurrentCampaign: (state, action: PayloadAction<CampaignApiModel>) => {
      state.currentCampaign = action.payload;
    },
    reset: () => initialRewardsState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getActiveReward.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getActiveReward.fulfilled, (state, { payload }) => {
      state.activeReward = payload;
    });
    reducersBuilder.addCase(getActivities.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getActivities.fulfilled, (state, { payload }) => {
      state.activities = payload;
    });
    reducersBuilder.addCase(getUserActivities.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getUserActivities.fulfilled, (state, { payload }) => {
      state.userActivities = payload;
    });
    reducersBuilder.addCase(getActiveCampaign.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getActiveCampaign.rejected, (state) => {
      state.isActiveCampaign = false;
      state.isLoading = false;
      state.activeCampaign = null;
    });
    reducersBuilder.addCase(getActiveCampaign.fulfilled, (state, { payload }) => {
      state.isActiveCampaign = !!payload;
      state.isLoading = false;
      if (payload) {
        state.activeCampaign = payload;
      }
    });
    reducersBuilder.addCase(getPrimaryCampaign.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getPrimaryCampaign.rejected, (state) => {
      state.isLoading = false;
      state.primaryCampaign = null;
    });
    reducersBuilder.addCase(getPrimaryCampaign.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.primaryCampaign = payload;
      }
    });
    reducersBuilder.addCase(getActiveIncentiveCampaignActivities.fulfilled, (state, { payload }) => {
      if (state.activeCampaign) state.activeCampaign.activities = payload as Activity[];
      state.isLoading = false;
    });

    reducersBuilder.addCase(getUserIncentiveCampaigns.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getUserIncentiveCampaigns.rejected, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getUserIncentiveCampaigns.fulfilled, (state, { payload }) => {
      state.userIncentiveCampaigns = payload.incentiveCampaigns;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getCampaignById.fulfilled, (state, { payload }) => {
      state.activeCampaign = payload;
    });
  },
});

export const { reset, setCurrentCampaign } = RewardsSlice.actions;
export default RewardsSlice.reducer;
