import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { httpClient } from '../../services/httpClient/httpClient';
import { CategoryEndpoints, getApiUrlForId, StoryEndpoints, TagsEndpoints } from '../../api/endpoints';
import { PaginationModel, StoriesDTO } from '../storeModels';
import { StoriesApiModel } from '../../api/models/stories';
import { PaginationRequest } from '../../common/commonTypes';
import { CategoryApiModel } from '../../api/models/category';
import { getFilterPublicId, removeduplicates } from '../../services/utilities';
import { TagsApiModel } from '../../api/models/videos';
import { StoryDetails } from '../../api/models/auth';
import { RequestError } from '../../api/models/common';

export enum StoryStatuses {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  MODERATED = 'MODERATED',
  PENDING_MODERATION = 'PENDING_MODERATION',
}

export const storiesInitialState: StoriesDTO = {
  stories: {
    items: [],
    totalPages: 0,
    totalItems: 0,
    page: 0,
    size: 0,
    isLoading: true,
  },
  currentStory: null,
  shareStory: null,
  shareStoryLoading: false,
  shareStoryRefetching: false,
  returnUrl: '',
  newStoriesCount: 0,
  mainCategory: null,
  filterPublicId: '',
  tags: {
    items: [],
    totalPages: 0,
    totalItems: 0,
    page: 0,
    size: 0,
    isLoading: true,
  },
  selectedTags: null,
};

interface getStoryVideosOptions extends PaginationRequest {
  isPublic?: boolean;
  tags?: string | null;
}

export const getStoryVideos = createAsyncThunk(
  'videos/getStories',
  async (options: getStoryVideosOptions, { getState, rejectWithValue }) => {
    const rootState = getState() as RootState;
    const params = {
      ...options,
      accountId: rootState.account.account.id,
      userId: rootState.me.id,
    };
    try {
      return await httpClient.get<PaginationRequest, PaginationModel<StoriesApiModel>>({
        url: StoryEndpoints.GetStories,
        requiresToken: true,
        params,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);
//TagsEndpoints
export const createTag = createAsyncThunk(
  'videos/createTag',
  async ({ videoId, userId, tag }: { videoId: string; userId: string; tag: string }, { rejectWithValue }) => {
    try {
      return await httpClient.post<{ videoId: string; userId: string; tag: string }, StoriesApiModel>({
        url: TagsEndpoints.AddTagToStory,
        requiresToken: true,
        payload: { videoId, userId, tag },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getTagsByUserId = createAsyncThunk(
  'videos/getTagsByUserId',
  async (userId: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ userId: string }, PaginationModel<TagsApiModel>>({
        url: getApiUrlForId(TagsEndpoints.GetTags, userId),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteTagFromStory = createAsyncThunk(
  'videos/deleteTagFromStory',
  async ({ videoId, tagId }: { videoId: string; tagId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.delete<{ videoId: string; tagId: string }, { id: string }>({
        url: TagsEndpoints.DeleteTagFromStory.replace(':videoId', videoId).replace(':tagId', tagId),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GetStoryByIdRequest {
  id: string;
}

export const getStoryByIdRequest = async (id: string) => {
  return httpClient.get<GetStoryByIdRequest, StoriesApiModel>({
    url: getApiUrlForId(StoryEndpoints.GetStoryById, id),
    requiresToken: false,
  });
};

export const getStoryById = createAsyncThunk(
  'videos/getStoryById',
  async ({ id }: GetStoryByIdRequest, { rejectWithValue }) => {
    try {
      return getStoryByIdRequest(id);
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface UpdateStoryByIdRequest {
  id: string;
  status?: StoryStatuses;
  featured?: boolean;
  deleted?: boolean;
  isPublic?: boolean;
  details?: StoryDetails;
  thumbnailUrl?: string;
}
interface DuplicatetoryByIdRequest {
  id: string;
  details: StoryDetails;
}
export const updateStoryById = createAsyncThunk(
  'videos/updateStoryById',

  async (params: UpdateStoryByIdRequest, { rejectWithValue }) => {
    try {
      return await httpClient.put<UpdateStoryByIdRequest, StoriesApiModel>({
        url: getApiUrlForId(StoryEndpoints.GetStoryById, params.id),
        requiresToken: true,
        payload: params,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateShareStoryById = createAsyncThunk(
  'videos/updateShareStoryById',
  async (params: UpdateStoryByIdRequest, { rejectWithValue }) => {
    try {
      return await httpClient.put<UpdateStoryByIdRequest, StoriesApiModel>({
        url: getApiUrlForId(StoryEndpoints.GetStoryById, params.id),
        requiresToken: true,
        payload: params,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GetStoryCategoriesResponse {
  items: CategoryApiModel[];
  page: number;
  size: number;
  totalItems: number;
  totalPages: number;
}

export const getCategoryById = createAsyncThunk('videos/getCategoryById', async (id: string, { rejectWithValue }) => {
  try {
    const data = await httpClient.get<{ accountId: string }, GetStoryCategoriesResponse>({
      url: CategoryEndpoints.GetCategoryById,
      requiresToken: false,
      params: {
        accountId: id,
      },
    });
    return data.items[0];
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.message);
  }
});

export const getReturnUrlByCategoryId = createAsyncThunk(
  'videos/getReturnUrlByCategoryId',
  async (id: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<PaginationRequest, CategoryApiModel>({
        url: `${CategoryEndpoints.GetCategoryById}${id}`,
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const createDuplicateVideo = createAsyncThunk(
  'stories/duplicate',
  async (story: { id: string; details: StoryDetails }, { rejectWithValue }) => {
    try {
      return await httpClient.post<DuplicatetoryByIdRequest, StoriesApiModel>({
        url: getApiUrlForId(StoryEndpoints.DuplicateStory, story.id),
        requiresToken: true,
        payload: story,
      });
    } catch (error) {
      return rejectWithValue((error as RequestError).response.data.message);
    }
  },
);
const storiesSlice = createSlice({
  name: 'stories',
  initialState: storiesInitialState,
  reducers: {
    getStory(state, action: PayloadAction<string>) {
      const story = state.stories.items.find((story) => story.id === action.payload);
      if (story) {
        state.currentStory = story;
      }
    },
    setSlectedTags(state, action: PayloadAction<string[] | null>) {
      state.selectedTags = action.payload;
    },
    reset: () => storiesInitialState,
    resetStoriesItems: (state) => {
      state.stories.items = [];
    },
    resetShareStory: (state) => {
      state.shareStory = null;
    },
    incrementStoriesCount: (state) => {
      state.newStoriesCount = state.newStoriesCount + 1;
    },
    resetStoriesCount: (state) => {
      state.newStoriesCount = 0;
    },
    shareStoryRefetching: (state, action: PayloadAction<boolean>) => {
      state.shareStoryRefetching = action.payload;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getStoryVideos.rejected, (state) => {
      state.stories.isLoading = false;
    });
    reducersBuilder.addCase(getStoryVideos.pending, (state) => {
      state.stories.isLoading = true;
    });
    reducersBuilder.addCase(getStoryVideos.fulfilled, (state, { payload }) => {
      state.stories.isLoading = false;
      state.stories.items = payload.items;
      state.stories.totalPages = payload.totalPages;
      state.stories.totalItems = payload.totalItems;
      state.stories.page = payload.page;
      state.stories.size = payload.size;
    });
    reducersBuilder.addCase(getStoryById.pending, (state) => {
      state.shareStoryLoading = true;
    });
    reducersBuilder.addCase(createDuplicateVideo.fulfilled, (state, { payload }) => {
      state.stories.items = [payload, ...state.stories.items];
    });
    reducersBuilder.addCase(getStoryById.fulfilled, (state, { payload }) => {
      state.stories.isLoading = false;
      state.shareStory = payload;
    });
    reducersBuilder.addCase(getStoryById.rejected, (state) => {
      state.stories.isLoading = false;
      state.shareStory = null;
    });
    reducersBuilder.addCase(updateStoryById.fulfilled, (state, { payload }) => {
      state.stories.isLoading = false;

      const storyIndex = state.stories.items.findIndex((story) => story.id === payload.id);
      const newArray = state.stories.items.slice();

      newArray[storyIndex] = payload;

      state.stories.items = [...newArray];
    });
    reducersBuilder.addCase(updateShareStoryById.fulfilled, (state, { payload }) => {
      state.stories.isLoading = false;
      const storyIndex = state.stories.items.findIndex((story) => story.id === payload.id);
      const storyItems = [...state.stories.items];
      storyItems[storyIndex] = payload;
      state.stories.items = storyItems;
      state.shareStory = payload;
    });
    reducersBuilder.addCase(getCategoryById.fulfilled, (state, { payload }) => {
      state.mainCategory = payload;
      state.filterPublicId = getFilterPublicId(payload.filterUrl);
    });
    reducersBuilder.addCase(getReturnUrlByCategoryId.fulfilled, (state, { payload }) => {
      state.returnUrl = payload.returnUrl;
    });
    reducersBuilder.addCase(getTagsByUserId.fulfilled, (state, { payload }) => {
      state.tags = payload;
    });
    reducersBuilder.addCase(createTag.fulfilled, (state, { payload }) => {
      const indexToUpdate = state.stories.items.findIndex((item) => item.id === payload.id);
      state.stories.isLoading = false;
      state.stories.items[indexToUpdate] = payload;
      state.tags.items = removeduplicates([...state.tags.items, ...payload.tags]);
    });
  },
});

export const {
  getStory,
  reset,
  resetShareStory,
  resetStoriesItems,
  incrementStoriesCount,
  resetStoriesCount,
  setSlectedTags,
  shareStoryRefetching,
} = storiesSlice.actions;
export default storiesSlice.reducer;
