export enum ClientsTypes {
  FUNDRAISING = 'FUNDRAISING',
  MARKETING = 'MARKETING',
  SALES = 'SALES',
  ECOMMERCE = 'ECOMMERCE',
  ETS = 'ETS',
  TRUBLU = 'TRUBLU',
  ATHSOL = 'ATHSOL',
  SV_HEALTH = 'MED',
  BRAND360 = 'BRAND360',
}
export type RequestError = {
  error: string;
  status: number;
  response: {
    status: number;
    data: {
      error: string;
      message: string;
      path: string;
      timestamp: string;
    };
  };
};
