import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IHttpClient, IHttpClientRequestParameters } from './IServersideHttpClient';


class ServersideHttpClient implements IHttpClient {
  get<T, U>(parameters: IHttpClientRequestParameters<T>): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      const { url, params } = parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (params) {
        options.params = params;
      }

      axios
        .get(url, options)
        .then((response: AxiosResponse<U>) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  post<T, U>(parameters: IHttpClientRequestParameters<T>): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      const { url, payload } = parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };
      axios
        .post(url, payload, options)
        .then((response: AxiosResponse<U>) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}

export const httpClient = new ServersideHttpClient();
