import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpClient } from '../../services/httpClient/httpClient';
import { GbpEndpoints, getApiUrlForId } from '../../api/endpoints';
import { GbpData, GbpReviews } from '../../api/models/gbp';

interface InitialState {
  isLoading: boolean;
  isError: boolean;
  gbpData: GbpData | null;
  gbpReviews: GbpReviews | null;
}

export const gbpInitialState: InitialState = {
  isError: false,
  isLoading: false,
  gbpData: null,
  gbpReviews: null,
};

export const getGbpData = createAsyncThunk('gbp/data', async (options: { accountId: string }, { rejectWithValue }) => {
  try {
    return await httpClient.get<undefined, GbpData>({
      url: getApiUrlForId(GbpEndpoints.GetGbpProfileData, options.accountId),
      requiresToken: false,
    });
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.status);
  }
});

export const getGbpReviews = createAsyncThunk(
  'gbp/reviews',
  async (options: { accountId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, GbpReviews>({
        url: getApiUrlForId(GbpEndpoints.GetGbpProfileReviews, options.accountId),
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.status);
    }
  },
);

const gbpSlice = createSlice({
  name: 'gbp',
  initialState: gbpInitialState,
  reducers: {},
  extraReducers: (reducerBuilder) => {
    reducerBuilder.addCase(getGbpData.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });

    reducerBuilder.addCase(getGbpData.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    reducerBuilder.addCase(getGbpData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.gbpData = payload;
    });

    reducerBuilder.addCase(getGbpReviews.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });

    reducerBuilder.addCase(getGbpReviews.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    reducerBuilder.addCase(getGbpReviews.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.gbpReviews = payload;
    });
  },
});

export const {} = gbpSlice.actions;

export default gbpSlice.reducer;
