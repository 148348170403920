import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LeaderBoardApiModel } from '../../api/models/leaderboard';
import { httpClient } from '../../services/httpClient/httpClient';
import { LeaderBoardEndpoints, getApiUrlForId } from '../../api/endpoints';
import { LeaderBoardDTO } from '../storeModels';

export const initialLeaderBoardState: LeaderBoardDTO = {
  items: [],
  campaignUser: {
    id: '',
    name: '',
    displayName: '',
    photoUrl: '',
    campaignPoints: 0,
    rank: 0,
  },
  isLoading: true,
};

export const getLeaderBoardRequest = createAsyncThunk('me/getLeaderBoardRequest', async (_options: string) => {
  try {
    return await httpClient.get<undefined, LeaderBoardApiModel[]>({
      url: getApiUrlForId(LeaderBoardEndpoints.GetLeaderBoard, _options),
      requiresToken: true,
    });
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.message);
  }
});

export const getLeaderBoardUserRequest = createAsyncThunk(
  'me/getLeaderBoardUserRequest',
  async (_options: { accountId: string; userId: string }) => {
    try {
      return await httpClient.get<undefined, LeaderBoardApiModel>({
        url: LeaderBoardEndpoints.GetLeaderBoardUser.replace(/:accountId/, _options.accountId).replace(
          /:userId/,
          _options.userId,
        ),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

const leaderBoardSlice = createSlice({
  name: 'leaderBoard',
  initialState: initialLeaderBoardState,
  reducers: {
    reset: () => initialLeaderBoardState,
  },
  extraReducers: (reducerBuilder) => {
    reducerBuilder.addCase(getLeaderBoardRequest.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(getLeaderBoardRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.items = payload;
    });
    reducerBuilder.addCase(getLeaderBoardUserRequest.fulfilled, (state, { payload }) => {
      state.campaignUser = payload;
    });
  },
});

export const { reset } = leaderBoardSlice.actions;
export default leaderBoardSlice.reducer;
