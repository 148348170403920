import makeStyles from '@mui/styles/makeStyles';

interface ToastStyleProps {
  backgroundColor: string;
}

export const useStyles = makeStyles<ToastStyleProps>(({ backgroundColor }) => ({
  '@keyframes snackAnim': {
    '0%, 100%': {
      opacity: 0,
      transform: 'translate(-50%, calc(100% + 16px))',
    },
    '20%, 80%': {
      opacity: 1,
      transform: 'translate(-50%, calc(-100% - 16px))',
    },
  },
  '@keyframes snackClose': {
    '0%': {
      opacity: 1,
      transform: 'translate(-50%, calc(-100% - 16px))',
    },
    '100%': {
      opacity: 0,
      transform: 'translate(-50%, calc(100% + 16px))',
    },
  },
  svgIcon: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    color: '#fff',
    border: '1px solid #fff',
    borderRadius: '100%',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  snackBlock: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: `${backgroundColor || '#5DD6AA'}`,
    position: 'fixed',
    top: '100%',
    left: '50%',
    transform: 'translate(-50%, calc(100% + 10px))',
    maxWidth: '280px',
    width: '100%',
    padding: '8px',
    borderRadius: '2px',
    zIndex: 2,
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '1',
    color: '#F9FFFD',
  },
  snackBlockIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '20px',
    width: '20px',
    height: '20px',
    flex: '0 1 20px',
  },
  snackBlockContent: {
    flex: '1',
    padding: '0 4px',
  },
  snackBlockClose: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '20px',
    width: '20px',
    height: '20px',
    flex: '0 1 20px',
  },
  open: {
    animation: `$snackAnim 6000ms ease-in-out forwards`,
  },
  close: {
    animation: `$snackClose 1000ms ease-in-out forwards`,
  },
}));
