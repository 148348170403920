export enum AppRoutes {
  Home = '/',
  UploadVideo = '/upload-video',
  Story = '/story',
  PrivacyPolicy = '/users-terms',
  Share = '/share',
  Auth = '/auth',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  Profile = '/profile',
  HowItWorks = '/how-it-works',
  ForgotPassword = '/forgot-password',
  StoryLink = '/s',
  BusinessProfile = '/business-profile',
  HistoryRewards = '/history-rewards',
  YourVideos = '/profile/videos',
  Rewards = '/profile/rewards',
  ProfileAccount = '/profile/account',
  ActivityHistory = '/profile/history',
  NotExist = '/not-exist',
  Welcome = '/welcome',
  test = '/test',
  video = '/video',
  socialverse = '/socialverse',
}
export enum autenticatedRoutes {
  Home = '/',
  HomeRoute = '/home',
  EmailUs = '/email-us',
  LiveChat = '/live-chat',
  UploadVideo = '/upload-video',
  UploadDone = '/upload-done',
  Story = '/story',
  References = '/references',
  PrivacyPolicy = 'https://www.socialvenu.com/users-terms',
  Profile = '/profile',
  HowItWorks = '/how-it-works',
  HistoryRewards = '/history-rewards',
  WelcomeAuth = '/welcome-auth',
  NotExist = '/not-exist',
  video = '/video',
}

export enum UnauthenticatedRoutes {
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  ForgotPassword = '/forgot-password',
  StoryLink = '/s',
  Auth = '/auth',
  Welcome = '/welcome',
  Share = '/share',
}

export enum ProfilePageRoutes {
  Account = 'account',
  Videos = 'videos',
  Rewards = 'rewards',
  ActivityHistory = 'history',
}
export const getApiUrlWithIdQueryParam = (apiUrl: string, id?: string): string => {
  return apiUrl + `?id=${id}`;
};
