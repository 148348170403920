export const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const AuthEndpoints = {
  GetUser: `${BASE_URL}/api/v1/users/:id`,
  RegisterUser: `${BASE_URL}/api/v1/users/register`,
  LoginUnverified: `${BASE_URL}/api/v1/auth/unverified`,
  ForgotPassword: `${BASE_URL}/api/v1/users/forgot-password`,
  ResetPassword: `${BASE_URL}/api/v1/users/reset-password`,
  LoginUser: `${BASE_URL}/api/v1/auth`,
  UpdateUser: `${BASE_URL}/api/v1/users/:id`,
  MergeUser: `${BASE_URL}/api/v1/users/:id/merge-user`,
  RefreshToken: `${BASE_URL}/api/v1/auth/refresh`,
  CheckEmail: `${BASE_URL}/api/v1/users/check-email`,
  ETSConnect: `${BASE_URL}/api/v1/users/ets-connect`,
  ValidatePwrToken: `${BASE_URL}/api/v1/users/validate-pwr-token`,
  RequestMagicLink: `${BASE_URL}/api/v1/auth/request-link`,
  LoginWithMagicLink: `${BASE_URL}/api/v1/auth/link`,
  RequestSignInCode: `${BASE_URL}/api/v1/auth/request-code`,
  RequestLoginCode: `${BASE_URL}/api/v1/auth/request-login-code`,
  LoginWithVerificationCode: `${BASE_URL}/api/v1/auth/code/:id`,
  ValidateCell: `${BASE_URL}/api/v1/auth/request-login-link`,
  AuthWithPartnerToken: `${BASE_URL}/api/v1/auth/partner-token`,
};

export const AccountEndpoints = {
  Account: `${BASE_URL}/api/v1/accounts/:id`,
  GetAccountAddress: `${BASE_URL}/api/v1/accounts/:id/address`,
  GetAccountProperties: `${BASE_URL}/api/v1/accounts/:id/properties?prefix=webapp`,
  GetOrganizationById: `${BASE_URL}/api/v1/organizations/:id`,
  GetDefaultSv: `${BASE_URL}/api/v1/accounts/:id/default-socialverse`,
};

export const StoryEndpoints = {
  GetStories: `${BASE_URL}/api/v1/videos`,
  CreateStory: `${BASE_URL}/api/v1/videos`,
  GetStoryById: `${BASE_URL}/api/v1/videos/:id`,
  TrackStoryViews: `${BASE_URL}/api/v1/videos/:id/view`,
  DuplicateStory: `${BASE_URL}/api/v1/videos/:id/duplicate`,
  TrackStoryCtaClicks: `${BASE_URL}/api/v1/videos/:id/cta-click`,
  TrackShareClicks: `${BASE_URL}/api/v1/videos/:id/share`,
};

export const getApiUrlForId = (apiUrl: string, id: string): string => {
  return apiUrl.replace(/:id/, id);
};

export const UploadEndpoints = {
  CreateUploadUrl: `${BASE_URL}/api/v1/videos/create-upload-signed-url`,
  CreateAvatarUploadUrl: `${BASE_URL}/api/v1/users/create-avatar-upload-signed-url`,
  CreateThumbnailUploadUrl: `${BASE_URL}/api/v1/videos/create-thumbnail-signed-url`,
};

export const CategoryEndpoints = {
  GetCategoryById: `${BASE_URL}/api/v1/video-categories/`,
};

export const RewardEndpoints = {
  GetActiveReward: `${BASE_URL}/api/v1/accounts/:id/active-reward`,
  GetActiveCampaign: `${BASE_URL}/api/v1/users/:id/active-campaign`,
  GetPrimaryCampaign: `${BASE_URL}/api/v1/accounts/:id/preferred-campaign`,
  GetActivities: `${BASE_URL}/api/v1/activities`,
  GetUserActivities: `${BASE_URL}/api/v1/users/:id/rewards/activities`,
  GetUserIncentiveCampaigns: `${BASE_URL}/api/v1/users/:id/incentive-campaigns`,
  GetCampaignById: `${BASE_URL}/api/v1/incentive-campaigns/:id`,
  GetCampaignByIdWithStats: `${BASE_URL}/api/v1/incentive-campaigns/:id?withStats=true`,
  GenerateRewardCardPreview: `${BASE_URL}/api/v1/incentive-campaigns/generate-image`,
};

export const LeaderBoardEndpoints = {
  GetLeaderBoard: `${BASE_URL}/api/v1/accounts/:id/leaderboard`,
  GetLeaderBoardUser: `${BASE_URL}/api/v1/accounts/:accountId/leaderboard/:userId`,
};
export const TagsEndpoints = {
  AddTagToStory: `${BASE_URL}/api/v1/video-tags`,
  DeleteTagFromStory: `${BASE_URL}/api/v1/video-tags/:videoId/:tagId`,
  GetTags: `${BASE_URL}/api/v1/tags/users/:id`,
};

export const CampaignsEndpoints = {
  GetActiveIncentiveCampaignActivities: `${BASE_URL}/api/v1/incentive-campaigns/:id/activities`,
};

export const GbpEndpoints = {
  GetGbpProfileData: `${BASE_URL}/api/v1/accounts/:id/gbp`,
  GetGbpProfileReviews: `${BASE_URL}/api/v1/accounts/:id/gbp-reviews`,
};
