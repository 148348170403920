import { FC } from 'react';

interface SubmitIconProps {
  classes?: string;
}

export const SubmitIcon: FC<SubmitIconProps> = ({ classes }) => {
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="10" height="10">
      <path fill="#fff" d="M2.99999 4.00002L1 2.00004L0 3.00003L2.99999 6L8 1.00001L7.00001 0L2.99999 4.00002Z" />
    </svg>
  );
};
