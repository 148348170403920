import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UIDTO } from '../storeModels';

export const UIInitialState: UIDTO = {
  sidebarIsOpen: undefined,
  autoplayWelcomeVideo: false,
  signUpFlowActiveTabIndex: 0,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: UIInitialState,
  reducers: {
    changeSidebarState(state, action: PayloadAction<boolean>) {
      state.sidebarIsOpen = action.payload;
    },
    setAutoplayWelcomeVideo(state, action: PayloadAction<boolean>) {
      state.autoplayWelcomeVideo = action.payload;
    },
    setSignUpFlowActiveTabIndex(state, action: PayloadAction<number>) {
      state.signUpFlowActiveTabIndex = action.payload;
    },
    reset: () => UIInitialState,
  },
});

export const { changeSidebarState, setSignUpFlowActiveTabIndex, setAutoplayWelcomeVideo, reset } = uiSlice.actions;
export default uiSlice.reducer;
