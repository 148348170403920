import { StoryDetails } from './models/auth';

export enum ErrorMessages {
  ExpiredJwtException = 'ExpiredJwtException',
  Unauthorized = 'Unauthorized',
  NotFound = 'Not Found',
  NotFoundException = 'NotFoundException',
}

export const defaultVenueSubDomain = 'sv-hero-dev';
export const defaultDonateUrl = 'https://org.eteamsponsor.com/ETS/supportUs/313124220';

export const DefaultCTABTNText = {
  ETS: 'Donate',
  FUNDRAISING: 'Donate',
  SALES: "Let's Connect",
  MARKETING: 'Check it out',
};

export type DefaultCTABTNTextKeys = 'ETS' | 'FUNDRAISING' | 'SALES' | 'MARKETING';

export const DEFAULT_STORY_DETAILS = (): StoryDetails => ({
  ctaBtnLinkUrl: '',
  ctaBtnText: DefaultCTABTNText.MARKETING,
  editable: 'full',
  metaDescription: 'Description',
  metaTitle: '',
  videoText: '',
  videoMessage: '',
});

export const DEFAULT_POINTS_GOAL = 100;
