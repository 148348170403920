import { useMemo } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import upload, { uploadInitialState } from './slices/upload';
import ui, { UIInitialState } from './slices/ui';
import stories, { storiesInitialState } from './slices/stories';
import account, { initialVenueState } from './slices/venue';
import me, { initialMeState } from './slices/me';
import rewards, { initialRewardsState } from './slices/rewards';
import leaderBoard, { initialLeaderBoardState } from './slices/leaderBoard';
import toast, { toastInitialState } from './slices/toast';
import gbp, { gbpInitialState } from './slices/gpb';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['ui', 'gbp', 'me'],
};

let store = configureStore({
  reducer: {
    ui,
    upload,
    stories,
    rewards,
    leaderBoard,
    account,
    me,
    toast,
    gbp,
  },
  devTools: process.env.APP_ENV === 'development',
});

const defaultInitialState: RootState = {
  upload: uploadInitialState,
  ui: UIInitialState,
  stories: storiesInitialState,
  rewards: initialRewardsState,
  leaderBoard: initialLeaderBoardState,
  account: initialVenueState,
  me: initialMeState,
  toast: toastInitialState,
  gbp: gbpInitialState,
};

const createStoreWithInitialValues = () => {
  return configureStore({
    reducer: persistReducer(
      persistConfig,
      combineReducers({
        upload,
        ui,
        stories,
        rewards,
        leaderBoard,
        account,
        me,
        toast,
        gbp,
      }),
    ),
    // preloadedState: initialState,
    devTools: true,
  });
};

export const initializeStore = () => {
  store = createStoreWithInitialValues();

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  // if (preloadedState && store) {
  //   _store = createStoreWithInitialValues({
  //     ...store.getState(),
  //     ...preloadedState
  //   });
  //   // Reset the current store
  //   // store = undefined;
  // }

  // // For SSG and SSR always create a new store
  // if (typeof window === 'undefined') return _store;
  // // Create the store once in the client
  // if (!store) store = _store;

  return store;
};

export const useStore = (initialState: RootState = defaultInitialState): typeof store => {
  const store = useMemo(() => initializeStore(), [initialState]);
  return store;
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const dispatch = store.dispatch;
